import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { App } from './components/App';

import { AuthorizationProvider } from './contexts/authorizationContext';
import { PopupProvider } from "./contexts/popupContext";
import reportWebVitals from './reportWebVitals';

import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthorizationProvider>
        <PopupProvider>
          <App />
        </PopupProvider>
      </AuthorizationProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
